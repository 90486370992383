<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="6" xl="3">
        <v-menu
          v-model="menuStartDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              flat
              dense
              background-color="white"
              v-model="startDate"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              label="Start Date"
            ></v-text-field>
          </template>
          <v-date-picker v-model="startDate" @input="menuStartDate = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="12" md="6" xl="3">
        <v-btn color="primary" @click="getReval" class="mr-2">Generate</v-btn>
        <v-btn color="success" @click="excel"><v-icon>mdi-file-excel</v-icon></v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="6" xl="6" v-for="(item, index) in items" :key="index + 'a'">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <td colspan="7" class="text-center">PT. GRAHA SUMBER PRIMA ELEKTRONIK</td>
              </tr>
              <tr>
                <td colspan="7" class="text-center">GENERAL LEDGER REPORT</td>
              </tr>
              <tr>
                <td colspan="7">PERIOD : DATE 1 TO DATE 2</td>
              </tr>
              <tr>
                <td colspan="7">ACCOUNT NUMBER : {{ item[0].accountNumber }}</td>
              </tr>
              <tr>
                <td colspan="7">DESCRIPTION : {{ item[0].accountName }}</td>
              </tr>
              <tr>
                <th>DATE</th>
                <th>DESCRIPTION</th>
                <th>REFF</th>
                <th>CURR</th>
                <th>DEBIT</th>
                <th>CREDIT</th>
                <th>BALANCE</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index2) in item" :key="index2 + 'b'">
                <td>{{ startDate }}</td>
                <td>{{ data.accountName }}</td>
                <td></td>
                <td>{{ data.currency }}</td>
                <td>{{ data.type === "Debit" ? formatPrice(data.amount) : formatPrice(0) }}</td>
                <td>{{ data.type === "Credit" ? formatPrice(data.amount) : formatPrice(0) }}</td>
                <td>{{ formatPrice(data.balance) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import fileDownload from "js-file-download";

export default {
  name: "reval",
  data() {
    return {
      menuStartDate: false,
      startDate: moment()
        .endOf("month")
        .format("YYYY-MM-DD"),
      items: [],
    };
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async getReval() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/reval", { startDate: this.startDate })
        .then(response => {
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async excel() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/revalExcel", { startDate: this.startDate })
        .then(response => {
          fileDownload(response.data, `report-reval.xlsx`);
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
};
</script>

<style></style>
